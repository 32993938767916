<template>
  <div class="">
  <slot name="toggle" :value="intValue" :toggle="toggle" :setValue="setValue"></slot>
  <slot v-if="intValue"></slot>
  </div>
</template>

<script setup>
import { ref } from 'vue'

const { value } = defineProps(['value'])
const intValue = ref(!!value)
const toggle = () => intValue.value = !intValue.value
const setValue = (v) => intValue.value = !!v


</script>

<style scoped>

</style>
